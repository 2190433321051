import api from '/~/core/api'
import ui from '/~/core/ui'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePoints } from '/~/composables/points'
import { Statement, useStatementAutoPayments } from '/~/composables/statements'
import PaymentCheckout from './PaymentCheckout'

const { burnPointsRateStatementOrder } = usePoints()

export class StatementCheckout extends PaymentCheckout {
  flowType = FlowType.statement
  orderType = FlowType.statement
  statement: any = null
  isDirectDebit = false

  get checkoutURL() {
    return `/v3/statement-orders/${this.statement?.id}`
  }

  get isReadyForPayment() {
    return Boolean(this.statement)
  }

  get payeeId() {
    return this.statement?.payeeId ?? null
  }

  get burnPointsRate() {
    return burnPointsRateStatementOrder.value
  }

  async initPayment(payment: any) {
    super.initPayment(payment)

    const { hasAccountAutoPayment, isPayNow } = useStatementAutoPayments()

    this.isSchedulingAllowed = false
    this.isDirectDebit = false
    this.statement = payment.statement
    this.amount = this.statement.subtotal

    const isDirectDebit = await hasAccountAutoPayment(
      payment.statement?.statementAccount?.id
    )

    this.isSchedulingAllowed = !(isPayNow.value || (ui.mobile && isDirectDebit))

    this.isDirectDebit = isDirectDebit

    await this.getProgramFees()
  }

  reset() {
    super.reset()

    this.statement = null
  }

  onPayFinished(data: any) {
    super.onPayFinished(data)

    this.statement = new Statement(data)
  }

  async preview() {
    return []
  }

  async getOrder(orderNumber: string) {
    this.statement = null
    this.confirmation.loading = true
    this.fetching = true

    try {
      const { data } = await api.get<any>(`/v3/statement-orders/${orderNumber}`)

      this.initPayment({
        statement: new Statement(data),
      })
      this.confirmation.order = data
      this.transactionFees = this.statement.transactionFees
      this.programFees = this.statement.programFees

      return new Statement(data)
    } catch (error) {
      console.error(error)
    } finally {
      this.confirmation.loading = false
      this.fetching = false
    }
  }
}

export default StatementCheckout

import { computed, reactive, ref } from 'vue'
import { AlgoliaWorker, getDefaultIndex } from '/~/core/algolia'
import cdn from '/~/utils/cdn'
import { useExtensions } from '/~/composables/extensions'
import { useSearch } from '/~/composables/search'
import { useUser } from '/~/composables/user'

const { registerAlgoliaSearch } = useSearch()
const { getManifestByName, getConfigByName, getMetaByName } = useExtensions()

const GIFTCARDS_MODULE_NAME = 'gift-cards'

const algolia = reactive(new AlgoliaWorker())
const categories = ref(null)
const searchQuery = ref('')
const cmsPageName = ref(null)

const module = computed(() => getManifestByName(GIFTCARDS_MODULE_NAME))
const isGiftCardsEnabled = computed(() => Boolean(module.value))
const config = computed(() => getConfigByName(GIFTCARDS_MODULE_NAME) || {})
const meta = computed(() => getMetaByName(GIFTCARDS_MODULE_NAME))
const isEmpty = computed(() => algolia.length === 0)
const algoliaIndexes = computed(() => config.value?.indexes ?? [])
const defaultIndex = computed(() => getDefaultIndex(config.value))
const label = computed(() => module.value?.label ?? 'Gift Cards')
const cardTypes = computed(() => {
  const types = [{ value: 'digital', label: 'Digital' }]

  if (isPhysicalEnabled.value) {
    types.push({ value: 'physical', label: 'Physical' })
  }

  return types
})

const searchConfig = computed(() => config.value?.search ?? {})
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)

const selectedCardType = ref(null)
const selectedSorting = ref(defaultIndex.value)
const selectedCategory = ref(null)

const sortValues = computed(() => {
  return algoliaIndexes.value.map((index) => {
    return {
      text: index.label,
      value: index.name,
      default: index.default,
    }
  })
})
const defaultSortValue = computed(
  () => sortValues.value.find((v) => v.default) || sortValues.value[0]
)

const routeState = computed(() => {
  const routeState = {
    params: {
      type: selectedCardType.value,
      category: selectedCategory.value,
    },
    query: {},
  }

  if (searchQuery.value) {
    routeState.query.search = searchQuery.value
  }
  if (selectedSorting.value && selectedSorting.value !== defaultIndex.value) {
    routeState.query.sort = selectedSorting.value
  }

  return routeState
})

const defaultFilters = computed(() => {
  const { user } = useUser()

  return [
    user.value.membershipId ? `memberships:${user.value.membershipId}` : '',
    config.value.filters || config.value.filter,
  ]
    .filter((v) => v)
    .join(' AND ')
})
const queryFilters = computed(() =>
  [defaultFilters.value, `physical:${selectedCardType.value === 'physical'}`]
    .filter((v) => v)
    .join(' AND ')
)

const isPhysicalEnabled = computed(() => meta.value?.physical ?? false)

const isFiltersSelected = computed(() => {
  return Boolean(
    searchQuery.value !== '' ||
      (selectedSorting.value !== defaultSortValue.value?.value &&
        selectedSorting.value) ||
      (selectedCategory.value !== categories.value?.[0]?.id &&
        selectedCategory.value)
  )
})

function resetFilters() {
  searchQuery.value = ''
  selectedSorting.value = defaultIndex.value
  selectedCategory.value = null
}

function initGiftcards() {
  algolia.setParams(config.value)

  const filters = [defaultFilters.value]

  if (!isPhysicalEnabled.value) {
    filters.push('physical:false')
  }

  registerAlgoliaSearch({
    group: 'Gift Cards',
    order: 0,
    config: config.value,
    algolia: {
      params: {
        filters: filters.join(' AND '),
        attributes: [
          'offer_name',
          'retailer_name',
          'retailer_slug',
          'retailer_alt_logo',
          'retailer_logo',
          'offer_external_id',
        ],
      },
    },
    mapping: (item) => {
      const imageUrl = cdn(item.retailer_logo).url()

      return {
        id: item.objectID,
        image: `${imageUrl}/-/preview/300x300/-/format/auto/-/quality/smart/`,
        label: item.retailer_name,
        offer: item.offer_name,
        target: {
          name: 'giftcards-retailer',
          params: {
            slug: item.retailer_slug,
            id: item.offer_external_id,
          },
        },
        meta: {
          physical: isPhysicalEnabled.value,
        },
      }
    },
  })
}

function syncState({ to }) {
  const firstParameterIsCardType =
    to.params?.type &&
    cardTypes.value.some((type) => type.value === to.params?.type)

  cmsPageName.value = to.meta.page
  selectedCardType.value = firstParameterIsCardType
    ? to.params?.type
    : 'digital'
  selectedCategory.value = firstParameterIsCardType
    ? to.params?.category
    : to.params?.type
  selectedSorting.value = to.query?.sort ?? defaultIndex.value
  searchQuery.value = to.query?.search ?? ''
}

async function getContent() {
  if (!algolia.isSet) {
    initGiftcards()
  }

  // NOTE: reset items to trigger processing in core/algolia
  algolia.reset()

  await algolia.getDataMultiple({
    multiple: [
      {
        indexName: selectedSorting.value,
        query: (searchQuery.value || '').trim(),
        params: {
          facetFilters: [
            `classifications:${algolia.decodePath(selectedCategory.value)}`,
          ],
          filters: queryFilters.value,
          facets: ['classifications'],
          distinct: true,
          attributesToRetrieve: [
            'offer_name',
            'offer_subtitle',
            'offer_logo',
            'retailer_name',
            'retailer_slug',
            'retailer_alt_logo',
            'retailer_logo',
          ],
        },
      },
      {
        indexName: defaultIndex.value,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['classifications'],
          facetFilters: [['classifications']],
          filters: queryFilters.value,
        },
      },
    ],
  })

  const [categoriesResult] = algolia.multiple
  const categoriesFacets = algolia.parseFacets(categoriesResult)

  if (categoriesFacets) {
    const newCategories = categoriesFacets.get('classifications')

    if (newCategories) {
      newCategories.values.unshift({
        label: 'All categories',
        count: newCategories.total,
        id: null,
      })

      categories.value = newCategories.values
    } else {
      categories.value = [
        {
          label: 'All categories',
          count: -1,
          id: null,
        },
      ]
    }
  }
}

export function useGiftcards() {
  return {
    module,
    algolia,
    routeState,
    isPhysicalEnabled,
    isEmpty,
    cardTypes,
    categories,
    sortValues,
    defaultSortValue,
    searchQuery,
    selectedCategory,
    selectedSorting,
    selectedCardType,
    isFiltersSelected,
    searchGroup,
    isGiftCardsEnabled,

    initGiftcards,
    syncState,
    getContent,
    resetFilters,
  }
}

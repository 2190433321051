import { reactive } from 'vue'
import { useBatchOrder } from '/~/composables/batch-order'
import { BatchOrderPayeesTable } from './core/BatchOrderPayeesTable'

const { batchOrder } = useBatchOrder()

const failedStatuses = [
  'batch_order_failed',
  'failed',
  'invalid',
  'blacklisted',
  'excluded',
]
const pendingStatuses = ['processing', 'new']
const completedStatuses = ['completed']
const allStatuses = [
  ...completedStatuses,
  ...pendingStatuses,
  ...failedStatuses,
]

const tabs = [
  { name: 'All', status: 'all', queryStatuses: allStatuses },
  { name: 'Completed', status: 'completed', queryStatuses: completedStatuses },
  { name: 'Pending', status: 'pending', queryStatuses: pendingStatuses },
  { name: 'Failed', status: 'failed', queryStatuses: failedStatuses },
]

const batchOrderPayeesTable = new BatchOrderPayeesTable({
  perPage: 10,
  statuses: allStatuses,
})

async function initBatchOrderPayeesTable() {
  if (!batchOrder.number) {
    return
  }
  batchOrderPayeesTable.loader.setUrl(
    `/v3/batch-orders/${batchOrder.number}/transactions`
  )
  batchOrderPayeesTable.tabs.setItems(tabs)
  batchOrderPayeesTable.tabs.setStatusItemsCount(
    batchOrder.transactionsStatusCount
  )
  batchOrderPayeesTable.setActiveTab(tabs[0])
}

async function initBatchOrderPayeesPreview() {
  if (!batchOrder.number) {
    return
  }
  batchOrderPayeesTable.reset()
  batchOrderPayeesTable.loader.setUrl(
    `/v3/batch-orders/${batchOrder.number}/transactions`
  )
  batchOrderPayeesTable.tabs.setItems(tabs)
  await batchOrderPayeesTable.setActiveTab(tabs[2])
}

export function useBatchOrderPayeesTable() {
  return {
    batchOrderPayeesTable,
    initBatchOrderPayeesTable,
    initBatchOrderPayeesPreview,
  }
}

import PurchaseCheckout from '/~/composables/checkout/core/Checkout/PurchaseCheckout'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

const { calculatePointsEarnedForPurchase } = usePoints()

type Product = {
  id: string
  name: string
  productImage: string
  price?: number
  quantity: number
  value: number
  type: string
  retailerName?: string

  giftRecipientId?: string
  message?: string
  gift?: boolean

  physical?: boolean
  shippingFees?: number
  priceGst?: number
}

export class QuickBuyCheckout extends PurchaseCheckout {
  items: Product[] = []
  billingAddressId: string | null = null
  shippingAddressId: string | null = null

  get checkoutItemsCount() {
    return this.items.length
  }

  get hasPhysicalItems() {
    return this.items.some((item) => item.physical)
  }

  get hasEstoreItems() {
    return this.items.some((item) => item.type === 'estore')
  }

  get hasDeliverableItems() {
    return this.hasPhysicalItems || this.hasEstoreItems
  }

  get isAddressSelectionAllowed() {
    const { isCashrewardsProvider } = useProvider()

    return isCashrewardsProvider.value || this.hasDeliverableItems
  }

  get shippingFees() {
    return this.items.reduce((value, item) => {
      return +(item.shippingFees ?? 0) + value
    }, 0)
  }

  get pointsEarned() {
    return calculatePointsEarnedForPurchase(
      this.items.map((item) => ({
        ...item,
        subTotal: item.quantity
          ? item.quantity * (item.price ?? 0)
          : item.value,
      }))
    )
  }

  getAddAddressRoute() {
    const { isCashrewardsProvider } = useProvider()

    return isCashrewardsProvider.value
      ? { name: 'addresses-add' }
      : super.getAddAddressRoute()
  }

  getEditAddressRoute(id: string) {
    const { isCashrewardsProvider } = useProvider()

    return isCashrewardsProvider.value
      ? {
          name: 'addresses-edit',
          params: {
            id,
          },
        }
      : super.getEditAddressRoute(id)
  }

  getPayload() {
    const payload: any = {
      paymentSources: this.getPaymentSources(),
      products: this.items,
      billingAddressId: this.billingAddressId,
      shippingAddressId: this.shippingAddressId,
    }

    if (this.payWithCoupons > 0) {
      payload.promotionCodes = this.selectedCoupons
    }

    return payload
  }

  reset() {
    this.resetCouponsAndPaymentMethods()

    this.amount = 0
    this.submitting = false
    this.date = null
    this.address = null
    this.programFees = 0
    this.loadingProgramFees = false
    this.description = null
    this.isSchedulingAllowed = true
    this.termsAccepted = false
    this.items = []
    this.billingAddressId = null
    this.shippingAddressId = null
  }

  get checkoutURL() {
    return '/v3/purchase-orders/quick-buy'
  }
}

export default QuickBuyCheckout

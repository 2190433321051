import Vue from 'vue'
import VueRouter from 'vue-router'
import emitter from '/~/core/emitter'
import { OtpLocalStorage } from '/~/extensions/otp/composables/core/OtpLocalStorage'
import { useAction } from '/~/composables/base/use-action'
import { useCms } from '/~/composables/cms'
import { useLogger } from '/~/composables/logger'
import { getConfigNameByRoute } from '/~/configurator/utils'
import authRoutes from '/~/router/auth-routes'
import globalRoutes from '/~/router/global-routes'
import { registerRouterExtensions } from '/~/router/router-extensions'
import {
  handleBlacklistedCms,
  handleQRCodeRedirection,
  handleUserAuthorization,
  setDocumentTitle,
  setDynamicHeroHeight,
  handleExtensionTasks,
  handleCheckout,
  handleCmsModals,
  AUTH_2FA_ROUTES,
  checkGuestAccess,
  handleThirdPartyUserAgreement,
} from '/~/router/router-handlers'
import {
  scrollBehavior,
  handleScrollPositionSaving,
} from '/~/router/scroll-behavior'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [],
  scrollBehavior,
})
const logger = useLogger('router')

registerRouterExtensions(router)
for (const route of [...authRoutes, ...globalRoutes]) {
  router.addRoute(route)
}

router.afterEach((to, from) => {
  const { pages } = useCms()

  router.historyLength = (router.historyLength || 0) + 1

  emitter.emit('router:after-each', {
    name: router.currentRoute.name,
    params: router.currentRoute.params,
    query: router.currentRoute.query,
    path: router.currentRoute.path,
  })

  const page = getConfigNameByRoute(router.currentRoute)

  const content = pages.value[page]

  if (to.path !== from.path && content?.settings?.pageAction) {
    const { handleAction } = useAction(content.settings.pageAction)

    handleAction()
  }
})

router.beforeEach(async (toRoute, fromRoute, next) => {
  handleCheckout(toRoute)
  handleBlacklistedCms(toRoute, next) // redirect home if blacklisted
  handleScrollPositionSaving(fromRoute)
  setDocumentTitle(toRoute)
  handleQRCodeRedirection(toRoute, next)
  handleCmsModals(toRoute, fromRoute)

  if (!handleUserAuthorization(toRoute, next)) return
  if (!(await handleExtensionTasks(toRoute, next))) return

  handleThirdPartyUserAgreement(toRoute, next)

  next()

  setDynamicHeroHeight()
})

async function handle401Error() {
  logger.debug('Session cookie is expired, clearing storage.')

  const otpStorage = new OtpLocalStorage('login')

  otpStorage.clear()

  // currentRoute is not updated yet, so we need to resolve it manually
  const { route } = router.resolve({
    path: window.location.pathname,
    query: window.location.search
      .slice(1)
      .split('&')
      .map((p) => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
    hash: window.location.hash,
  })

  if (route.name === 'auth-login') return

  if (
    (route?.name && AUTH_2FA_ROUTES.includes(route?.name)) ||
    !checkGuestAccess(route)
  ) {
    logger.debug('Redirecting to login page.')
    await router.replace({ name: 'auth-login' })
  }
}

if (eonx.jwtExpired) {
  handle401Error()
}

export default router
